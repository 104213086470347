import { useState, useEffect } from 'react'
import { saveLevel } from './level-state'
import { gameServer } from './constants'
import { useHistory } from 'react-router-dom'
import { MetadataForm, useMetadataFormReducer } from './metadata-form'
import latinize from 'latinize'

function useExistingLevels() {
  const [levels, setLevels] = useState<
    { levelName: string; levelid: string }[] | null
  >(null)
  useEffect(() => {
    fetch(gameServer + '/levels')
      .then(v => v.json())
      .then(v => {
        if (Array.isArray(v.levels)) setLevels(v.levels)
      })
      .catch(e => {
        setLevels(l => (!l ? [] : l))
        console.error(e)
      })
  }, [])
  return levels
}

function levelNameToId(name: string) {
  return latinize(name)
    .toLowerCase()
    .replace(/[^0-9a-z-]/g, '-')
}

export function LevelPicker() {
  const history = useHistory()
  const levels = useExistingLevels()
  const [file, setFile] = useState<null | Blob>(null)
  const [form, dispatchForm] = useMetadataFormReducer()
  const [submitting, setSubmitting] = useState(false)
  if (submitting) {
    return <div>Vytvářím úroveň</div>
  }

  if (file) {
    return (
      <div
        css={{
          margin: '50px auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h2>Nová píseň</h2>
        <MetadataForm
          form={form}
          dispatch={dispatchForm}
          onDone={() => {
            setSubmitting(true)
            const levelid = levelNameToId(form.levelName)
            saveLevel({ ...form, levelid, beats: [], example: false }, file)
              .then(() => {
                history.push('/level/' + levelid)
              })
              .catch(e => {
                console.error(e)
                setSubmitting(false)
              })
          }}
        />
      </div>
    )
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        marginTop: 50,
      }}
    >
      <div>
        <h2 css={{ textAlign: 'left' }}>Dostupné úrovně</h2>
        {levels === null ? (
          <span>Načítám seznam úrovní...</span>
        ) : levels.length < 1 ? (
          <span>
            Žádné, nebo neběží server s uloženými úrovněmi.
            <br />
            Pokud vám není přístupná kopie hry PaintPong můžete vybrat falešnou
            úroveň níže.
          </span>
        ) : (
          <div>
            {levels.map(l => (
              <button
                key={l.levelid}
                type="button"
                onClick={() => {
                  history.push('/level/' + l.levelid)
                }}
              >
                {l.levelName}
              </button>
            ))}
          </div>
        )}
        <div>
          <button
            type="button"
            onClick={() => {
              history.push('/level/example')
            }}
          >
            Falešná úroveň
          </button>
        </div>
      </div>
      <label css={{ marginTop: 20 }}>
        <h2>Nová píseň</h2>
        Nahrát soubor:{' '}
        <input
          type="file"
          onChange={evt => {
            evt.preventDefault()
            const files = evt.target.files
            if (files && files.length > 0) {
              setFile(files[0])
            }
            evt.target.value = ''
          }}
        />
      </label>
    </div>
  )
}
