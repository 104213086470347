import { Color } from 'three'

export const colors = {
  black: new Color(0, 0, 0),
  yellow: new Color(1, 1, 0),
  disabled: new Color(0.7, 0.7, 1),
  gray: new Color(0.7, 0.7, 0.7),
  green: new Color(0, 1, 0),
}
export type BeatColor = keyof typeof colors
export function isBeatColor(color: any): color is BeatColor {
  return typeof color === 'string' && color in colors
}

export type Beat = {
  type: 'oneshot'
  id: number
  time: number
  color: BeatColor
  position: { x: number; y: number }
}

export type HoverTime =
  | {
      time: number
      closestBeat: null | Beat
    }
  | (() => {
      time: number
      closestBeat: null | Beat
    })

export const gameServer = 'http://127.0.0.1:8018'
