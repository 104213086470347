import {
  useReducer,
  useCallback,
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  Dispatch,
  useLayoutEffect,
  useRef,
} from 'react'
import { HoverTime, Beat } from './constants'
type Action =
  | {
      type: 'set-handler'
      handler: (time: number, closestBeat: Beat | null) => 'keep' | 'remove'
      name: string
    }
  | { type: 'unset-handler' }
const reducer = (
  state: {
    handler: (time: number, closestBeat: Beat | null) => 'keep' | 'remove'
    name: string
  } | null,
  action: Action,
) => {
  if (action.type === 'unset-handler') return null
  if (action.type === 'set-handler') {
    return { handler: action.handler, name: action.name }
  }

  throw new Error('Unknown action')
}

const Context = createContext<
  ReturnType<typeof useClickHandlerContext>['clickContext'] | null
>(null)

const HoverTimeContext = createContext<
  ReturnType<typeof useClickHandlerContext>['hoverTime'] | null
>(null)

export function ClickContext({ children }: PropsWithChildren<{}>) {
  const { clickContext, hoverTime } = useClickHandlerContext()
  return (
    <Context.Provider value={clickContext}>
      <HoverTimeContext.Provider value={hoverTime}>
        {children}
      </HoverTimeContext.Provider>
    </Context.Provider>
  )
}

export function useHoverTime() {
  const ctx = useContext(HoverTimeContext)
  if (!ctx) throw new Error('No HoverTime context')
  return ctx
}

function triggerCallback(
  state: ReturnType<typeof reducer>,
  dispatch: Dispatch<Action>,
) {
  return (
    time: number,
    closestBeat: Beat | null,
    fallback: (time: number) => void,
  ) => {
    if (state) {
      const ret = state.handler(time, closestBeat)
      if (ret === 'remove') dispatch({ type: 'unset-handler' })
    } else {
      fallback(time)
    }
  }
}

function useClickHandlerContext() {
  const hoverTime = useRef<HoverTime>({ time: -1, closestBeat: null })
  const [state, dispatch] = useReducer(reducer, null)
  const setHandler = useCallback(
    (
      name: string,
      handler:
        | null
        | ((time: number, closestBeat: Beat | null) => 'keep' | 'remove'),
      immediate = false,
    ) => {
      if (immediate) {
        if (handler)
          setImmediate(() => {
            const { time, closestBeat } =
              typeof hoverTime.current === 'function'
                ? hoverTime.current()
                : hoverTime.current
            handler(time, closestBeat)
          })
      } else {
        if (!handler) dispatch({ type: 'unset-handler' })
        else dispatch({ type: 'set-handler', name, handler })
      }
    },
    [hoverTime],
  )
  const triggerRef = useRef<ReturnType<typeof triggerCallback>>(
    triggerCallback(state, dispatch),
  )
  useLayoutEffect(() => {
    triggerRef.current = triggerCallback(state, dispatch)
  }, [state])
  const trigger = useCallback<ReturnType<typeof triggerCallback>>(
    (time, closestBeat, fallback) => {
      triggerRef.current(time, closestBeat, fallback)
    },
    [],
  )
  return {
    clickContext: [state?.name, setHandler, trigger, !!state?.handler],
    hoverTime,
  } as const
}
export function useClickHandler() {
  const ctx = useContext(Context)
  if (!ctx) throw new Error('Click context not found')
  return {
    name: ctx[0],
    setClickHandler: ctx[1],
    triggerClickHandler: ctx[2],
    hasHandler: ctx[3],
  }
}

export function AccButton({
  accelerator,
  action,
  children,
  disabled,
  keyCodes,
}: PropsWithChildren<{
  accelerator?: string
  action: (immediate: boolean) => void
  disabled?: boolean
  keyCodes?: readonly number[]
}>) {
  const hoverTime = useHoverTime()
  useEffect(() => {
    if (!accelerator) return
    function listener(evt: KeyboardEvent) {
      console.log(evt.keyCode)
      if (evt.key === accelerator || (keyCodes || []).includes(evt.keyCode)) {
        const ht =
          typeof hoverTime.current === 'function'
            ? hoverTime.current()
            : hoverTime.current
        if (!disabled && ht.time >= 0) {
          action(true)
          evt.preventDefault()
          evt.stopPropagation()
        }
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [accelerator, action, disabled, hoverTime, keyCodes])
  return (
    <button type="button" onClick={() => action(false)} disabled={disabled}>
      {children}
      {accelerator ? <> [{accelerator}]</> : null}
    </button>
  )
}

export function ToolButton({
  name,
  action,
  accelerator,
  children,
  behavior = 'keep',
  keyCodes,
  disabled,
}: PropsWithChildren<{
  name: string
  action: (time: number, closestBeat: Beat | null) => void
  accelerator?: string
  behavior?: 'keep' | 'remove'
  keyCodes?: readonly number[]
  disabled?: boolean
}>) {
  const { setClickHandler } = useClickHandler()
  return (
    <AccButton
      keyCodes={keyCodes}
      accelerator={accelerator}
      disabled={disabled}
      action={immediate => {
        setClickHandler(
          name,
          (time, closestBeat) => {
            action(time, closestBeat)
            return behavior
          },
          immediate,
        )
      }}
    >
      {children}
    </AccButton>
  )
}
