import { Component, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

type PropsBase = PropsWithChildren<{ fallback?: JSX.Element }>
type Props = PropsBase & {
  location: ReturnType<typeof useLocation>
}
class TheErrorBoundary extends Component<Props> {
  state = { error: null }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.setState({ error: null })
    }
  }
  render() {
    if (this.state.error) {
      const { fallback } = this.props
      if (fallback) return fallback
      return <h1>Něco se pokazilo</h1>
    }

    return this.props.children
  }
}

export function ErrorBoundary({ children, ...rest }: PropsBase) {
  const location = useLocation()
  return (
    <TheErrorBoundary {...rest} location={location}>
      {children}
    </TheErrorBoundary>
  )
}
