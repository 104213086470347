import { useLayoutEffect } from 'react'

const noValue = Symbol('noValue')
export class Resource<T> {
  private value: T | typeof noValue = noValue
  private error: Error | typeof noValue = noValue
  readonly promise: Promise<T>
  constructor(promise: Promise<T>) {
    this.promise = promise
    promise.then(
      v => {
        this.value = v
      },
      err => {
        this.error = err
      },
    )
  }
  read() {
    if (this.error !== noValue) throw this.error
    if (this.value !== noValue) return this.value
    throw this.promise
  }
}

let pendingResources = 0
const onZeroPendingResources = new Set<() => void>()
let zeroPendingResourcesTimer: ReturnType<typeof setTimeout> | null = null
function addPendingResource(v: Promise<any>) {
  if (zeroPendingResourcesTimer) clearTimeout(zeroPendingResourcesTimer)
  pendingResources++
  v.then(() => {
    pendingResources--
    if (pendingResources <= 0) {
      zeroPendingResourcesTimer = setTimeout(() => {
        if (pendingResources > 0) return
        onZeroPendingResources.forEach(c => c())
      }, 15 * 1000)
    }
  }).catch(() => {})
}

export class ResourceCache<T extends { dispose(): void }, Key> {
  private readonly loader: (key: Key) => Promise<T>
  private readonly cache = new Map<
    Key,
    {
      counter: number
      resource: Resource<T>
    }
  >()
  constructor(loader: (key: Key) => Promise<T>) {
    this.loader = loader
    onZeroPendingResources.add(() => {
      for (const v of this.cache.values()) {
        if (v.counter <= 0) {
          v.resource.promise.then(p => p.dispose()).catch(e => console.error(e))
        }
      }
    })
  }
  get(key: Key) {
    let value = this.cache.get(key)
    if (!value) {
      value = {
        counter: 0,
        resource: new Resource(this.loader(key)),
      }
      this.cache.set(key, value)
      addPendingResource(value.resource.promise)
    }
    return value.resource
  }
  markUsed(key: Key) {
    const v = this.cache.get(key)
    if (!v) return
    v.counter++
  }
  markUnused(key: Key) {
    const v = this.cache.get(key)
    if (!v) return
    v.counter--
    if (v.counter <= 0)
      v.resource.promise.then(v => v.dispose()).catch(() => {})
  }
}

export function useResourceCacheValue<
  T extends { dispose(): void },
  Key extends object
>(cache: ResourceCache<T, Key>, key: Key) {
  const v = cache.get(key)
  useLayoutEffect(() => {
    cache.markUsed(key)
    return () => cache.markUnused(key)
  }, [key, cache])
  return v.read()
}
