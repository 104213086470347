import {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { ResourceCache, useResourceCacheValue, Resource } from 'utils/resource'
import { timing } from 'utils/timing'
import { Beat } from './constants'
import metronome from 'audio/metronome.wav'
import { copyBuffer } from 'utils/buffer'

function createAudioContext(): AudioContext {
  return new (window.AudioContext || (window as any).webkitAudioContext)()
}

const metronomeOffset = 0.3 // s
const stabilityTime = 0.1 // s
function publicInterface(audio: Audio) {
  let playing = false
  let startedAt = 0
  let realStartedAt = 0
  let stoppedAt = 0
  async function resume() {
    const audioCtx = audio.audioCtx
    if (audioCtx.state === 'suspended') await audioCtx.resume()
  }

  function onEnd() {
    if (!stoppedAt) {
      startedAt = 0
      stoppedAt = 0
    }
    setPlaying(false)
  }

  const events = new EventTarget()
  function setPlaying(val: boolean) {
    playing = val
    self.scheduleBeats()
    events.dispatchEvent(new Event('playingChanged'))
  }

  let unscheduleBeats = () => {}

  const self = {
    setTime: (offset: number) => {
      if (playing) {
        self.play(offset)
      } else {
        stoppedAt = startedAt + offset
      }
    },
    play: (offset_?: number) => {
      resume()
        .then(() => {
          if (audio.source) {
            audio.source.stop()
            audio.source.disconnect()
            audio.source.removeEventListener('ended', onEnd)
          }
          audio.source = audio.audioCtx.createBufferSource()
          audio.source.addEventListener('ended', onEnd)
          audio.source.buffer = audio.buffer
          audio.source.connect(audio.audioCtx.destination)
          const offset =
            typeof offset_ === 'number'
              ? offset_
              : stoppedAt
              ? stoppedAt - startedAt
              : 0
          realStartedAt = audio.audioCtx.currentTime + stabilityTime
          audio.source.start(realStartedAt, offset)
          startedAt = realStartedAt - offset
          stoppedAt = 0
          setPlaying(true)
        })
        .catch(e => console.error(e))
    },
    pause: () => {
      if (audio.source) {
        stoppedAt = audio.audioCtx.currentTime + stabilityTime
        audio.source.stop(stoppedAt)
        playing = false
      }
    },
    toggle: () => {
      if (playing) self.pause()
      else self.play()
    },
    get playing() {
      return playing
    },
    get buffer() {
      return audio.buffer
    },
    get time() {
      if (realStartedAt > audio.audioCtx.currentTime) {
        return realStartedAt - startedAt
      }
      if (!playing) {
        if (stoppedAt) return stoppedAt - startedAt
        return 0
      }
      return audio.audioCtx.currentTime - startedAt
    },
    listen(event: string, listener: () => void) {
      events.addEventListener(event, listener)
      return () => events.removeEventListener(event, listener)
    },
    setBeats: (beats: readonly Beat[]) => {
      audio.setBeats(beats)
    },
    scheduleBeats: () => {
      unscheduleBeats()
      if (!playing) return

      const sources: AudioBufferSourceNode[] = []
      function schedule(time: number) {
        const source = audio.audioCtx.createBufferSource()
        source.buffer = audio.metronomeBuffer
        source.connect(audio.audioCtx.destination)
        const onended = () => {
          source.stop()
          source.disconnect()
          source.removeEventListener('ended', onended)
        }
        source.addEventListener('ended', onended)
        source.start(time)
        sources.push(source)
      }
      for (const beat of audio.beats) {
        const time = beat.time + startedAt - metronomeOffset
        if (time > audio.audioCtx.currentTime) schedule(time)
      }
      unscheduleBeats = () => sources.forEach(s => s.stop())
    },
  }
  return self
}

function createAudio(inBufferV: ArrayBuffer): Promise<Audio> {
  const inBuffer = copyBuffer(inBufferV)

  const audioCtx = createAudioContext()
  const done = timing('decodeAudioData')
  return Promise.all([
    audioCtx.decodeAudioData(inBuffer),
    fetch(metronome)
      .then(r => r.arrayBuffer())
      .then(b => audioCtx.decodeAudioData(b)),
  ]).then(([buffer, metronomeBuffer]) => {
    done()
    return new Audio(audioCtx, buffer, metronomeBuffer)
  })
}

class Audio {
  audioCtx: ReturnType<typeof createAudioContext>
  buffer: AudioBuffer
  metronomeBuffer: AudioBuffer
  source: AudioBufferSourceNode | null = null
  beats: readonly Beat[] = []

  publicInterface: ReturnType<typeof publicInterface>

  constructor(
    audioContext: ReturnType<typeof createAudioContext>,
    buffer: AudioBuffer,
    metronomeBuffer: AudioBuffer,
  ) {
    this.audioCtx = audioContext
    this.buffer = buffer
    this.metronomeBuffer = metronomeBuffer

    this.publicInterface = publicInterface(this)
  }

  setBeats(beats: readonly Beat[]) {
    this.beats = beats
    this.publicInterface.scheduleBeats()
  }

  disposed = false
  dispose() {
    if (!this.disposed) {
      this.disposed = true
      this.audioCtx.close().catch(e => {
        console.error(e)
      })
    }
  }
}

const audioCache = new ResourceCache(createAudio)
const AudioContext = createContext<Audio | null>(null)
export function AudioProvider({
  children,
  src,
}: PropsWithChildren<{
  src: Resource<ArrayBuffer>
}>) {
  const audio = useResourceCacheValue(audioCache, src.read())

  return <AudioContext.Provider value={audio}>{children}</AudioContext.Provider>
}
function useAudioPrivate() {
  const ctx = useContext(AudioContext)
  if (!ctx) throw new Error('Cannot find audio context')
  return ctx
}
export function useAudio() {
  return useAudioPrivate().publicInterface
}

export function useAudioPlaying() {
  const audio = useAudio()
  const [playing, setPlaying] = useState(audio.playing)
  useEffect(() => {
    setPlaying(audio.playing)
    return audio.listen('playingChanged', () => {
      setPlaying(audio.playing)
    })
  }, [audio])
  return playing
}
