import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as SW from './service-worker'
import App from './app'

const enableInProd = true
// Maybe disable HMR if enabling this
const enableInDev = false
if ((enableInDev && __DEV__) || (enableInProd && !__DEV__)) {
  console.log('Registering SW')
  SW.register({
    onUpdate: wb => wb.messageSW({ type: 'SKIP_WAITING' }),
    swUrl: '/service-worker-bach.js',
  })
} else {
  console.log('Unregistering SW')
  SW.unregister()
}

const element = document.getElementById('app')

if (element) {
  Promise.resolve()
    .then(() => {
      const Router: React.FC<{}> = ({ children }) => (
        <BrowserRouter>{children}</BrowserRouter>
      )
      const app = <App router={Router} />
      ReactDOM.render(app, element)
    })
    .catch(e => {
      console.error(e)
      element.innerText = 'Něco se pokazilo'
      element.classList.add('not-supported')
    })
}
