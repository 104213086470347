import '@emotion/core'

import { useAudio, useAudioPlaying } from './use-audio'
import { useZoomData, useDrawToCanvas } from './wave-rendering-loading'
import {
  useReducer,
  useEffect,
  useState,
  useRef,
  PropsWithChildren,
} from 'react'
import {
  unstable_scheduleCallback,
  unstable_cancelCallback,
  unstable_LowPriority,
} from 'scheduler'
import { useClickHandler, AccButton, useHoverTime } from './tools'
import { Beat } from './constants'

function useZoomLevel(
  canvasRef: React.RefObject<HTMLCanvasElement>,
  zoomData: readonly { min: { length: number } }[] | string,
  canvasWidth: number,
) {
  let minZoom = 0
  while (
    typeof zoomData !== 'string' &&
    zoomData[minZoom] &&
    zoomData[minZoom].min.length < canvasWidth &&
    minZoom < zoomData.length
  )
    minZoom++
  if (minZoom > 0) minZoom--
  const ret = useReducer(
    (
      state: { zoom: number; offset: number },
      action: { mode: '+' | '-'; at?: number } | { mode: 'scroll'; by: number },
    ) => {
      if (typeof zoomData === 'string') return state
      const canvas = canvasRef.current
      if (!canvas) return state

      let { zoom, offset } = state
      if (action.mode === 'scroll') {
        const visiblePortion = canvas.width / zoomData[zoom].min.length
        offset += Math.sign(action.by) * visiblePortion * 0.125
      } else {
        const { mode, at } = { at: 0.5, ...action }

        if (mode === '+') {
          zoom++
        } else if (mode === '-') {
          zoom--
        } else throw new Error('Fail')

        if (zoom < 0) return state
        if (zoom >= zoomData.length) return state

        const beforeVisiblePortion =
          canvas.width / zoomData[zoom + (mode === '+' ? -1 : 1)].min.length
        const cursorInDataOffset = offset + at * beforeVisiblePortion
        const nowVisiblePortion = canvas.width / zoomData[zoom].min.length
        offset = cursorInDataOffset - at * nowVisiblePortion
      }

      if (zoom < minZoom) zoom = minZoom

      const nowVisiblePortion =
        zoomData[minZoom].min.length / zoomData[zoom].min.length
      if (offset < 0) offset = 0
      if (!canvasRef.current) offset = 0
      else if (offset > 1 - nowVisiblePortion) offset = 1 - nowVisiblePortion

      return { zoom, offset }
    },
    { zoom: minZoom, offset: 0 },
  )
  const [, changeZoomLevel] = ret

  useEffect(() => {
    const canvas = canvasRef.current
    function listener(this: HTMLCanvasElement, evt: WheelEvent) {
      if (evt.shiftKey) {
        evt.preventDefault()
        changeZoomLevel({
          mode: 'scroll',
          by: evt.deltaY / this.getBoundingClientRect().width,
        })
      } else if (evt.ctrlKey) {
        evt.preventDefault()
        changeZoomLevel({
          mode: evt.deltaY < 0 ? '+' : '-',
          at: evt.offsetX / this.getBoundingClientRect().width,
        })
      }
    }
    if (canvas) {
      canvas.addEventListener('wheel', listener, { passive: false })
      return () => canvas.removeEventListener('wheel', listener)
    }
  }, [canvasRef, changeZoomLevel])
  return { ...ret[0], minZoom } as const
}

function calcCanvasSize(mode: string) {
  return {
    width: window.innerWidth,
    height:
      (window.innerHeight - 45) *
      (mode === 'split' ? 0.5 : mode === 'preview' ? 0.2 : 1),
  }
}

function useCanvasSize(mode: string) {
  const [canvasWidth, setCanvasWidth] = useState(calcCanvasSize(mode))

  useEffect(() => {
    let raf: ReturnType<typeof requestAnimationFrame> | undefined = undefined
    function listener() {
      if (raf) cancelAnimationFrame(raf)
      raf = requestAnimationFrame(() => {
        setCanvasWidth(old => {
          const n = calcCanvasSize(mode)
          if (old.height !== n.height || old.width !== n.width) return n
          return old
        })
        raf = undefined
      })
    }
    listener()
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [mode])

  return canvasWidth
}

function getTimeToHueCoef(
  canvas: { width: number },
  audio: ReturnType<typeof useAudio>,
  zoom: { min: { length: number } },
) {
  return (zoom.min.length / canvas.width / audio.buffer.duration) * 360
}

function createDrawBag(
  canvas: CanvasRenderingContext2D,
  offset: number,
  audio: ReturnType<typeof useAudio>,
  zoom: { min: { length: number } },
) {
  const width = canvas.canvas.width
  const height = canvas.canvas.height

  const timeOffset = offset * audio.buffer.duration

  const scaleX = zoom.min.length / audio.buffer.duration
  const singleHorizontalPixel = 1 / scaleX

  function xToTime(x: number) {
    return (x / zoom.min.length + offset) * audio.buffer.duration
  }
  function xToHue(x: number) {
    return ((offset * zoom.min.length + x) / width) * 360
  }
  function timeToHue(time: number) {
    return time * getTimeToHueCoef(canvas.canvas, audio, zoom)
  }
  return {
    canvas,
    width,
    height,
    xToTime,
    xToHue,
    timeToHue,
    offset,
    singleHorizontalPixel: singleHorizontalPixel,
    times: {
      left: timeOffset,
      right: xToTime(width),
    },
  }
}
type DrawBag = ReturnType<typeof createDrawBag>
function drawBeat(beat: Beat, { canvas, singleHorizontalPixel }: DrawBag) {
  canvas.fillStyle = '#333'
  if (beat.color === 'black') canvas.fillStyle = 'white'
  else if (beat.color) canvas.fillStyle = beat.color
  canvas.fillRect(beat.time, -1, singleHorizontalPixel, 2)
}

function drawZoom(
  zoom: { min: Float32Array; max: Float32Array },
  { offset, width, canvas, height, xToHue }: DrawBag,
) {
  const absOffset = Math.floor(offset * zoom.min.length)
  for (let x = 0; x < width; x++) {
    if (x + absOffset >= zoom.min.length) break
    const min = zoom.min[x + absOffset]
    const max = zoom.max[x + absOffset]

    canvas.fillStyle = `hsl(${xToHue(x)}, 80%, 65%)`
    canvas.fillRect(
      x,
      ((min + 1) * height) / 2,
      1,
      ((max - min) * height) / 2 + 1,
    )
  }
}

function drawCursor(
  time: number,
  { timeToHue, canvas, singleHorizontalPixel }: DrawBag,
) {
  canvas.fillStyle = `hsl(${timeToHue(time) + 180}, 80%, 50%)`
  canvas.fillRect(time, -1, singleHorizontalPixel, 2)
}

function drawScrollBar(
  visiblePortion: number,
  { canvas, width, offset, height }: DrawBag,
) {
  const scrollHeight = Math.max(Math.ceil(height * 0.015), 15)
  canvas.fillStyle = '#aaa'
  canvas.fillRect(
    width * offset,
    height - scrollHeight,
    width * visiblePortion,
    scrollHeight,
  )
}

type NotString<T> = T extends string ? never : T
function WaveCanvas({
  data,
  beats,
  mode,
  onTimeToHueCoefChange,
}: {
  data: NotString<ReturnType<typeof useZoomData>>
  beats: readonly Beat[]
  mode: string
  onTimeToHueCoefChange: (c: number) => void
}) {
  const hoverTime = useHoverTime()
  const {
    triggerClickHandler: onClick,
    hasHandler: hasClickHandler,
  } = useClickHandler()
  const audio = useAudio()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasSize = useCanvasSize(mode)
  const { zoom: zoomLevel, offset, minZoom } = useZoomLevel(
    canvasRef,
    data,
    canvasSize.width,
  )

  const timeToHueCoef = canvasRef.current
    ? getTimeToHueCoef(canvasRef.current, audio, data[minZoom])
    : 360
  useEffect(() => {
    onTimeToHueCoefChange(timeToHueCoef)
  }, [onTimeToHueCoefChange, timeToHueCoef])

  useDrawToCanvas(canvasRef, ({ width, height, ctx: canvas }) => {
    const zoom = data[zoomLevel]
    if (!zoom) return

    canvas.clearRect(0, 0, width, height)
    canvas.fillStyle = '#000'
    canvas.fillRect(0, 0, width, height)

    const bag = createDrawBag(canvas, offset, audio, zoom)

    function doDraw<T>(fn: (arg: T, bag: DrawBag) => void, arg: T) {
      canvas.save()
      canvas.scale(1 / bag.singleHorizontalPixel, height / 2)
      canvas.translate(-bag.times.left, 1)
      fn(arg, bag)
      canvas.restore()
    }

    for (const beat of beats) {
      doDraw(drawBeat, beat)
    }

    canvas.save()
    drawZoom(zoom, bag)
    canvas.restore()

    doDraw(drawCursor, audio.time)
    drawScrollBar(data[minZoom].min.length / zoom.min.length, bag)
  })

  // click handler
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    function getBeatFromTime(time: number) {
      let closestBeat: Beat | null = null
      for (const beat of beats) {
        if (closestBeat === null) {
          closestBeat = beat
          continue
        }
        if (Math.abs(beat.time - time) < Math.abs(closestBeat.time - time)) {
          closestBeat = beat
        }
      }
      return closestBeat
    }

    function evtGetBeatAndTime(canvas: HTMLCanvasElement, evt: MouseEvent) {
      const time = evtToTime(canvas, evt)
      const closestBeat = getBeatFromTime(time)

      return { time, closestBeat }
    }

    function atToTime(canvas: HTMLCanvasElement, at: number) {
      const visiblePortion = canvas.width / data[zoomLevel].min.length
      const cursorInDataOffset = offset + at * visiblePortion
      return audio.buffer.duration * cursorInDataOffset
    }

    function evtToTime(canvas: HTMLCanvasElement, evt: MouseEvent) {
      const at = evt.offsetX / canvas.getBoundingClientRect().width
      return atToTime(canvas, at)
    }

    let mouseDown = false
    let wasPlaying = false
    function downListener(this: HTMLCanvasElement, evt: MouseEvent) {
      evt.preventDefault()
      mouseDown = true
      wasPlaying = false
      if (audio.playing && !hasClickHandler) {
        wasPlaying = true
        audio.pause()
      }
      if (!hasClickHandler) {
        audio.setTime(evtToTime(this, evt))
      }
    }
    function upListener(this: HTMLCanvasElement, evt: MouseEvent) {
      evt.preventDefault()
      mouseDown = false

      const { time, closestBeat } = evtGetBeatAndTime(this, evt)
      onClick(time, closestBeat, time => {
        if (wasPlaying) audio.play(time)
      })
    }

    function moveListener(this: HTMLCanvasElement, evt: MouseEvent) {
      if (typeof data === 'string') return
      hoverTime.current = evtGetBeatAndTime(this, evt)
      if (mouseDown && !hasClickHandler) {
        audio.setTime(hoverTime.current.time)
      }
    }

    function outListener(this: HTMLCanvasElement, evt: MouseEvent) {
      hoverTime.current = { time: -1, closestBeat: null }
      if (mouseDown && !hasClickHandler) {
        if (evt.offsetX < 0) {
          audio.setTime(atToTime(this, 0))
        } else if (evt.offsetX > this.getBoundingClientRect().width) {
          audio.setTime(atToTime(this, 1))
        }
      }
    }

    if (typeof hoverTime.current !== 'function' && hoverTime.current.time > 0) {
      hoverTime.current.closestBeat = getBeatFromTime(hoverTime.current.time)
    }

    function windowMouseUp() {
      mouseDown = false
    }

    canvas.addEventListener('mousemove', moveListener)
    canvas.addEventListener('mouseout', outListener)
    canvas.addEventListener('mousedown', downListener)
    canvas.addEventListener('mouseup', upListener)
    window.addEventListener('mouseup', windowMouseUp)
    return () => {
      canvas.removeEventListener('mousemove', moveListener)
      canvas.removeEventListener('mouseout', outListener)
      canvas.removeEventListener('mousedown', downListener)
      canvas.removeEventListener('mouseup', upListener)
      window.removeEventListener('mouseup', windowMouseUp)
    }
  }, [
    audio,
    beats,
    canvasRef,
    data,
    hasClickHandler,
    hoverTime,
    offset,
    onClick,
    zoomLevel,
  ])

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize.width}
      height={canvasSize.height}
      css={{
        border: '1px solid #999',
        background: 'black',
      }}
    />
  )
}
function CanvasPlaceholder({
  children,
  mode,
}: PropsWithChildren<{ mode: string }>) {
  const canvasSize = useCanvasSize(mode)
  return (
    <div
      css={{
        width: '100%',
        background: 'black',
        border: '1px solid #999',
        position: 'relative',
      }}
    >
      <div
        css={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
      <canvas width={canvasSize.width} height={canvasSize.height} css={{}} />
    </div>
  )
}

export function Waveform({
  beats,
  children,
  mode,
  onTimeToHueCoefChange,
}: PropsWithChildren<{
  beats: readonly Beat[]
  mode: string
  onTimeToHueCoefChange: (c: number) => void
}>) {
  const audio = useAudio()
  const data = useZoomData(audio.buffer)

  return (
    <div css={{ position: 'relative' }}>
      {data === 'error' ? (
        <CanvasPlaceholder mode={mode}>Něco se pokazilo</CanvasPlaceholder>
      ) : data === 'loading' ? (
        <CanvasPlaceholder mode={mode}>
          Načítám časovou osu...
        </CanvasPlaceholder>
      ) : (
        <>
          <div
            css={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'flex-end',
              color: 'white',
              padding: 10,
              left: 0,
              right: 0,
              pointerEvents: 'none',
            }}
          >
            {children}
            <PlayProgress />
          </div>
          <WaveCanvas
            data={data}
            beats={beats}
            mode={mode}
            onTimeToHueCoefChange={onTimeToHueCoefChange}
          />
        </>
      )}
    </div>
  )
}

function PlayProgress() {
  const audio = useAudio()
  const [{ progress, duration }, setProgress] = useState({
    progress: audio.time,
    duration: audio.buffer.duration,
  })

  useEffect(() => {
    let raf = unstable_scheduleCallback(unstable_LowPriority, cb, { delay: 10 })
    function cb() {
      const v = {
        progress: audio.time,
        duration: audio.buffer.duration,
      }
      setProgress(cur =>
        cur.progress === v.progress && cur.duration === v.duration ? cur : v,
      )
      raf = unstable_scheduleCallback(unstable_LowPriority, cb, { delay: 10 })
    }
    return () => unstable_cancelCallback(raf)
  }, [audio.buffer.duration, audio.time])

  return (
    <div>
      <Time time={progress} fraction={2} /> /{' '}
      <Time time={duration} fraction={0} />
    </div>
  )
}

export function Time({
  time,
  fraction = 0,
}: {
  time: number
  fraction?: number
}) {
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60
  return (
    <>
      {minutes}:{seconds < 10 ? '0' : ''}
      {seconds.toFixed(fraction)}
    </>
  )
}

export function PlayPauseButton() {
  const audio = useAudio()
  const playing = useAudioPlaying()
  return (
    <AccButton accelerator=" " action={audio.toggle}>
      {playing ? 'stop' : 'play'}
    </AccButton>
  )
}
