import { memo, Suspense, PropsWithChildren, useState, useEffect } from 'react'
import { AudioProvider } from './use-audio'
import { PlayPauseButton, Waveform } from './wave'
import { Resource } from 'utils/resource'
import { useClickHandler, ClickContext, ToolButton, AccButton } from './tools'
import { useLevelState, useLevelStateAutosave } from './level-state'
import { Level3D } from './level-3d'
import { BeatColor, gameServer } from './constants'
import { MetadataForm } from './metadata-form'
import { useParams, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import exampleOgg from '../audio/05-Binrpilot-Underground-shorter.ogg'
import exampleJson from '../audio/05-Binrpilot-example.json'

export const Editor = memo(function Wave() {
  const { levelid, mode = 'split' } = useParams<{
    levelid: string
    mode: string
  }>()
  const [file, setFile] = useState<{
    levelid: string
    buffer: Resource<ArrayBuffer>
    initial: Resource<any>
  } | null>(null)
  const history = useHistory()
  const filelevelid = file?.levelid
  useEffect(() => {
    if (filelevelid !== levelid) {
      const example = levelid === 'example'
      let ogg = example ? exampleOgg : gameServer + '/audio/' + levelid + '.ogg'
      setFile({
        levelid,
        buffer: new Resource(fetch(ogg).then(v => v.arrayBuffer())),
        initial: new Resource(
          example
            ? Promise.resolve({ ...exampleJson, levelid })
            : fetch(gameServer + '/level/' + levelid)
                .then(v => v.json())
                .then(v => ({ ...v, levelid })),
        ),
      })
    }
  }, [filelevelid, levelid])

  if (!file) return <div>Načítám...</div>

  const base = css`
    all: unset;
    padding: 10px;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  `
  const active = [
    base,
    css`
      font-weight: bold;
    `,
  ]
  const nonactive = base

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <Suspense
        fallback={
          <>
            <div>
              <button type="button" onClick={() => void history.push('/')}>
                Vybrat jiný soubor
              </button>
            </div>
            <div>Načítám soubor...</div>
          </>
        }
      >
        <AudioProvider src={file.buffer}>
          <div
            css={css`
              display: flex;
              background: #3a3a3a;
              color: white;
              justify-content: flex-end;
              height: 45px;
            `}
          >
            <button
              type="button"
              css={nonactive}
              onClick={() =>
                void history.push(
                  '/level/' +
                    levelid +
                    (mode !== 'metadata' ? '/metadata' : ''),
                )
              }
            >
              {mode !== 'metadata' ? 'Upravit metadata' : 'Zpět'}
            </button>
            <div css={{ flexGrow: 1 }} />
            <button
              type="button"
              css={mode === 'timeline' ? active : nonactive}
              onClick={() =>
                void history.push('/level/' + levelid + '/timeline')
              }
            >
              Časová osa
            </button>
            <button
              type="button"
              css={mode === 'split' ? active : nonactive}
              onClick={() => void history.push('/level/' + levelid)}
            >
              Půlené
            </button>
            <button
              type="button"
              css={mode === 'preview' ? active : nonactive}
              onClick={() =>
                void history.push('/level/' + levelid + '/preview')
              }
            >
              Náhled
            </button>
            <div css={{ flexGrow: 1 }} />
            <button
              css={nonactive}
              type="button"
              onClick={() => void history.push('/')}
            >
              Vybrat jiný soubor
            </button>
          </div>
          <div css={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <EditorMain file={file.initial} buffer={file.buffer} />
          </div>
        </AudioProvider>
      </Suspense>
    </div>
  )
})

function EditorMain({
  file,
  buffer,
}: {
  file: Resource<any>
  buffer: Resource<ArrayBuffer>
}) {
  const history = useHistory()
  const [levelState, dispatchBeats] = useLevelState(file.read())
  const { beats, hasRedo, hasUndo, canPaste } = levelState
  const { mode = 'split' } = useParams<{ mode?: string }>()
  const saved = useLevelStateAutosave(levelState, buffer.read())

  const [timeToHueCoef, setTimeToHueCoef] = useState(360)

  useEffect(() => {
    if (!['metadata', 'default', 'timeline', 'preview', 'split'].includes(mode))
      history.replace('/level/' + levelState.levelid)
  }, [history, levelState.levelid, mode])

  return (
    <ClickContext>
      {mode === 'metadata' ? (
        <div
          css={{
            marginTop: 50,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2>Upravit metadata</h2>
          <MetadataForm
            form={levelState}
            dispatch={dispatchBeats}
            onDone={() => void history.push('/level/' + levelState.levelid)}
          />
        </div>
      ) : null}
      <div
        css={{
          display: ['split', 'timeline', 'preview'].includes(mode)
            ? 'flex'
            : 'none',
          flexDirection: 'row',
          height: 'calc(100vh - 45px)',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(100vw)',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Level3D
            beats={beats}
            dispatch={dispatchBeats}
            mode={mode}
            timeToHueCoef={timeToHueCoef}
          />
          <Waveform
            beats={beats}
            mode={mode}
            onTimeToHueCoefChange={setTimeToHueCoef}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: 0,
            background: 'white',
            padding: 10,
            width: 250,
          }}
        >
          <div css={{ textAlign: 'center' }}>
            {levelState.example
              ? 'Falešná úroveň'
              : saved
              ? 'Uloženo'
              : 'Ukládám...'}
          </div>
          <AccButton
            accelerator="z"
            disabled={!hasUndo}
            action={() => dispatchBeats({ type: 'undo' })}
          >
            Undo
          </AccButton>
          <AccButton
            accelerator="y"
            disabled={!hasRedo}
            action={() => dispatchBeats({ type: 'redo' })}
          >
            Redo
          </AccButton>
          <ToolButton
            accelerator="c"
            behavior="remove"
            name="Kopírovat pozici a barvu"
            action={(time, beat) => {
              if (beat) dispatchBeats({ type: 'copy', id: beat.id })
            }}
          >
            Kopírovat pozici a barvu
          </ToolButton>
          <ToolButton
            accelerator="p"
            disabled={!canPaste}
            name="Vložit pozici a barvu"
            action={(time, beat) => {
              if (beat) dispatchBeats({ type: 'paste', id: beat.id })
            }}
          >
            Vložit pozici a barvu
          </ToolButton>
          <PlayPauseButton />
          <ToolButton
            accelerator="j"
            name="Přidat úder"
            action={time => dispatchBeats({ type: 'add', time })}
          >
            Přidat úder
          </ToolButton>
          <ToolButton
            accelerator="d"
            name="Odstranit úder"
            action={(time, beat) => {
              if (beat) dispatchBeats({ type: 'remove', id: beat.id })
            }}
          >
            Odstranit úder
          </ToolButton>
          <ColorButton
            accelerator="1"
            keyCodes={[49, 97]}
            color="black"
            dispatch={dispatchBeats}
          >
            Černá
          </ColorButton>
          <ColorButton
            accelerator="2"
            keyCodes={[50, 98]}
            color="gray"
            dispatch={dispatchBeats}
          >
            Šedá
          </ColorButton>
          <ColorButton
            accelerator="3"
            keyCodes={[51, 99]}
            color="green"
            dispatch={dispatchBeats}
          >
            Zelená
          </ColorButton>
          <ColorButton
            accelerator="4"
            keyCodes={[52, 100]}
            color="yellow"
            dispatch={dispatchBeats}
          >
            Žlutá
          </ColorButton>
          <ColorButton
            accelerator="0"
            keyCodes={[48, 96]}
            color="disabled"
            dispatch={dispatchBeats}
          >
            Vypnout
          </ColorButton>

          <ClickHandlerStatus />
        </div>
      </div>
    </ClickContext>
  )
}

function ClickHandlerStatus() {
  const { name, setClickHandler } = useClickHandler()
  return (
    <div>
      {name ? (
        <div css={{ paddingTop: 10 }}>
          Aktivní nástroj:{' '}
          <button
            type="button"
            onClick={() => {
              setClickHandler('', null)
            }}
          >
            Zrušit
          </button>
          <br />
          {name}
        </div>
      ) : null}
    </div>
  )
}

function ColorButton({
  accelerator,
  keyCodes,
  color,
  dispatch,
  children,
}: PropsWithChildren<{
  accelerator: string
  keyCodes: readonly number[]
  color: BeatColor
  dispatch: ReturnType<typeof useLevelState>[1]
}>) {
  return (
    <ToolButton
      name={'Nastavuji barvu na: ' + color}
      accelerator={accelerator}
      keyCodes={keyCodes}
      action={(time, beat) => {
        if (beat) dispatch({ type: 'set-color', value: color, id: beat.id })
      }}
    >
      {children}
    </ToolButton>
  )
}
