import * as Comlink from 'comlink'

import { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { ZoomWorkerExport } from './zoom-worker'
import { timing } from 'utils/timing'

export function useZoomData(buffer: AudioBuffer) {
  const [data, setData] = useState<
    | ReadonlyArray<{ min: Float32Array; max: Float32Array }>
    | 'loading'
    | 'error'
  >('loading')

  useEffect(() => {
    if (!buffer) return setData('error')
    setData('loading')
    const markEnd = timing('zoom-calculation')
    const zoomWorker = new Worker('/dist/zoom-worker.js')
    const api = Comlink.wrap<ZoomWorkerExport>(zoomWorker)
    const data = Array(buffer.numberOfChannels)
      .fill(0)
      .map((_, c) => buffer.getChannelData(c))

    const cancelled = { current: false }
    const cb = Comlink.proxy(
      (zoom: { min: Float32Array; max: Float32Array }) => {
        if (!cancelled.current) {
          setData(d => (typeof d === 'string' ? [zoom] : [...d, zoom]))
        }
        return Promise.resolve()
      },
    )
    let finished = false
    const finish = Comlink.proxy(() => {
      markEnd()
      finished = true
      ;(api as any)[Comlink.releaseProxy]()
      zoomWorker.terminate()
    })

    api.beginWork(data, cb, finish).catch((e: any) => {
      console.error(e)
      setData('error')
      finish()
    })

    return () => {
      if (!finished) {
        ;(api as any)[Comlink.releaseProxy]()
        zoomWorker.terminate()
      }
      cancelled.current = true
    }
  }, [buffer])
  return typeof data === 'string'
    ? data
    : data.length < 10
    ? ('loading' as const)
    : data
}

export function useDrawToCanvas(
  canvasRef: React.RefObject<HTMLCanvasElement>,
  draw: (args: {
    ctx: CanvasRenderingContext2D
    width: number
    height: number
  }) => void,
) {
  const drawRef = useRef(draw)
  useLayoutEffect(() => {
    drawRef.current = draw
  })
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return
    var canvasCtx = canvas.getContext('2d')
    if (!canvasCtx) return

    const ctx = canvasCtx
    const doDraw = () => {
      drawRef.current({
        ctx,
        width: canvas.width,
        height: canvas.height,
      })
      raf = requestAnimationFrame(doDraw)
    }
    let raf = requestAnimationFrame(doDraw)
    return () => {
      cancelAnimationFrame(raf)
    }
  }, [canvasRef])
}
