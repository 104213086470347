import { Workbox } from 'workbox-window'

// This is mostly copypasted from CRA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

export type ServiceWorkerRegisterConfig = {
  onUpdate?: (registration: Workbox) => void
  swUrl: string
}

export function register(config: ServiceWorkerRegisterConfig) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready
          .then(() => {
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://bit.ly/CRA-PWA',
            )
          })
          .catch(() => {})
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(config)
      }
    })
  }
}

function registerValidSW(config: ServiceWorkerRegisterConfig) {
  const wb = new Workbox(config.swUrl)

  const l = (name: string) => (e: any) =>
    console.log(name, JSON.stringify(e, null, 2))

  /* flow for new:
   * installed -> controllerchange -> controlling -> activated
   * flow for update:
   * installed -> redundant -> controllerchange -> controlling
   */

  let isRedundant = false
  function reloadIfRedundant() {
    if (isRedundant) {
      console.log('Reloading...')
      window.location.reload()
    }
  }

  wb.addEventListener('activated', l('activated'))
  wb.addEventListener('externalactivated', l('externalactivated'))
  wb.addEventListener('externalinstalled', l('externalinstalled'))
  wb.addEventListener('externalwaiting', l('externalwaiting'))
  wb.addEventListener('installed', l('installed'))
  wb.addEventListener('message', l('message'))
  wb.addEventListener('redundant', evt => {
    l('redundant')(evt)
    isRedundant = true
  })

  wb.addEventListener('controlling', e => {
    l('controlling')(e)
    reloadIfRedundant()
  })
  navigator.serviceWorker.addEventListener('controllerchange', evt => {
    // service worker controlling this window changed, reloading
    l('controllerchange')(evt)
    reloadIfRedundant()
  })

  wb.addEventListener('waiting', () => {
    // At this point, the updated precached content has been fetched,
    // but the previous service worker will still serve the older
    // content until all client tabs are closed.
    console.log(
      'New content is available and will be used when all ' +
        'tabs for this page are closed. See http://bit.ly/CRA-PWA.',
    )
    // Execute callback
    if (config && config.onUpdate) {
      config.onUpdate(wb)
    }
  })

  wb.register().catch(() => {})
}

function checkValidServiceWorker(config: ServiceWorkerRegisterConfig) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(config.swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && !contentType.includes('javascript'))
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready
          .then(registration => {
            registration
              .unregister()
              .then(() => {
                window.location.reload()
              })
              .catch(() => {})
          })
          .catch(() => {})
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(config)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => registration.unregister())
      .then(() => console.log('Unregistered'))
      .catch(e => console.error(e))
  }
}
