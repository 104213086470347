import React from 'react'
import { Editor } from 'components/editor'
import { ErrorBoundary } from 'components/error-boundary'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { LevelPicker } from 'components/level-picker'

function ChangeSelection() {
  const history = useHistory()
  return (
    <div css={{ textAlign: 'center' }}>
      <h1>Něco se pokazilo</h1>
      <button type="button" onClick={() => history.replace('/')}>
        Zpět na výběr písně
      </button>
    </div>
  )
}

export default function App(props: { router: React.FC<{}> }) {
  const Router = props.router
  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route path="/" exact>
            <ErrorBoundary>
              <LevelPicker />
            </ErrorBoundary>
          </Route>
          <Route path="/level/:levelid/:mode?" exact>
            <ErrorBoundary fallback={<ChangeSelection />}>
              <Editor />
            </ErrorBoundary>
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </React.StrictMode>
  )
}
