import { PropsWithChildren, useReducer } from 'react'

const initialForm = {
  songName: '',
  songAuthor: '',
  levelAuthor: '',
  levelName: '',
}

type MetadataFormValues = typeof initialForm

const reducer = (
  state: MetadataFormValues,
  action: { type: keyof MetadataFormValues; value: string },
): MetadataFormValues => {
  return { ...state, [action.type]: action.value }
}

export function useMetadataFormReducer() {
  return useReducer(reducer, initialForm)
}

export function MetadataForm({
  form,
  dispatch,
  onDone,
}: {
  form: typeof initialForm
  dispatch: (action: { type: keyof typeof initialForm; value: string }) => void
  onDone: () => void
}) {
  const mustEditMeta =
    !form.levelAuthor || !form.levelName || !form.songAuthor || !form.songName
  return (
    <form
      onSubmit={evt => {
        evt.preventDefault()
        if (!mustEditMeta) onDone()
      }}
      css={{ display: 'flex', flexDirection: 'column' }}
    >
      <TextInput dispatch={dispatch} state={form} field="levelName">
        Jméno úrovně
      </TextInput>
      <TextInput dispatch={dispatch} state={form} field="levelAuthor">
        Jméno autora úrovně
      </TextInput>
      <TextInput dispatch={dispatch} state={form} field="songName">
        Jméno písně
      </TextInput>
      <TextInput dispatch={dispatch} state={form} field="songAuthor">
        Jméno autora písně
      </TextInput>
      <div>
        <button>Pokračovat</button>
      </div>
    </form>
  )
}

function TextInput({
  state,
  dispatch,
  field,
  children,
}: PropsWithChildren<{
  state: typeof initialForm
  dispatch: (action: { type: keyof typeof initialForm; value: string }) => void
  field: keyof typeof initialForm
}>) {
  return (
    <label>
      {children}:{' '}
      <input
        value={state[field]}
        onChange={evt => dispatch({ type: field, value: evt.target.value })}
      />
    </label>
  )
}
