import { timing } from './timing'

export function copyBuffer(buffer: ArrayBuffer) {
  const endCopy = timing('buffer copy')
  const out = new ArrayBuffer(buffer.byteLength)
  const a = new DataView(buffer)
  const b = new DataView(out)
  for (let i = 0; i < buffer.byteLength; i++) {
    b.setUint8(i, a.getUint8(i))
  }
  endCopy()
  return out
}
