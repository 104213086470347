import { useEffect, useReducer, useRef, useState, useMemo } from 'react'
import { useAudio } from './use-audio'
import { BeatColor, isBeatColor, Beat, gameServer } from './constants'
import { copyBuffer } from 'utils/buffer'

type Position = Beat['position']
type UndoHistoryElement = {
  prev: UndoHistoryElement | null
} & (
  | {
      type: 'add'
      id: number
    }
  | { type: 'remove'; beat: Beat }
  | {
      id: number
      type: 'set'
      field: 'time'
      prevValue: number
    }
  | { id: number; type: 'set-color'; prevValue: BeatColor }
  | { id: number; type: 'set-position'; prevValue: Position }
  | { type: 'songName'; prevValue: string }
  | { type: 'songAuthor'; prevValue: string }
  | { type: 'levelAuthor'; prevValue: string }
  | { type: 'levelName'; prevValue: string }
  | { type: 'paste'; id: number; prevValue: Pick<Beat, 'position' | 'color'> }
)
type RedoHistoryElement = {
  next: RedoHistoryElement | null
} & (
  | {
      type: 'add'
      beat: Beat
    }
  | { type: 'remove'; id: number }
  | {
      id: number
      type: 'set'
      field: 'time'
      prevValue: number
    }
  | { id: number; type: 'set-color'; prevValue: BeatColor }
  | { id: number; type: 'set-position'; prevValue: Position }
  | { type: 'songName'; prevValue: string }
  | { type: 'songAuthor'; prevValue: string }
  | { type: 'levelAuthor'; prevValue: string }
  | { type: 'levelName'; prevValue: string }
  | { type: 'paste'; id: number; prevValue: Pick<Beat, 'position' | 'color'> }
)

type BeatAction =
  | { type: 'add'; time: number }
  | {
      type: 'set-time'
      value: number
      id: number
    }
  | { type: 'remove'; id: number }
  | { type: 'set-color'; value: BeatColor; id: number }
  | { type: 'set-position'; value: Position; id: number }
  | { type: 'songName'; value: string }
  | { type: 'songAuthor'; value: string }
  | { type: 'levelAuthor'; value: string }
  | { type: 'levelName'; value: string }
  | { type: 'paste'; id: number }
  | { type: 'copy'; id: number }

type BeatsState = {
  beats: Beat[]
  history: UndoHistoryElement | null
  future: RedoHistoryElement | null
  clipboard: null | Pick<Beat, 'position' | 'color'>
  songName: string
  songAuthor: string
  levelAuthor: string
  levelName: string
  levelid: string
}

type HistoryHelper<
  Hist extends UndoHistoryElement | RedoHistoryElement,
  T extends UndoHistoryElement['type']
> = Hist extends { type: T } ? Hist : never

type Action<type extends UndoHistoryElement['type']> = {
  undo: (
    state: BeatsState,
    v: HistoryHelper<UndoHistoryElement, type>,
  ) => BeatsState
  redo: (
    state: BeatsState,
    v: HistoryHelper<RedoHistoryElement, type>,
  ) => BeatsState
}

function metaAction<
  T extends 'levelAuthor' | 'songAuthor' | 'songName' | 'levelName'
>(t: T) {
  return {
    undo(state: BeatsState, hist: HistoryHelper<UndoHistoryElement, T>) {
      return {
        ...state,
        [t]: (hist as any).prevValue,
        history: hist.prev,
        future: {
          next: state.future,
          type: t,
          prevValue: state[t],
        },
      }
    },
    redo(state: BeatsState, hist: HistoryHelper<RedoHistoryElement, T>) {
      return {
        ...state,
        [t]: (hist as any).prevValue,
        history: {
          prev: state.history,
          type: t,
          prevValue: state[t],
        },
        future: hist.next,
      }
    },
  }
}

const actions: {
  [type in UndoHistoryElement['type']]: Action<type>
} = {
  levelAuthor: metaAction('levelAuthor'),
  songAuthor: metaAction('songAuthor'),
  songName: metaAction('songName'),
  levelName: metaAction('levelName'),

  paste: {
    undo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) throw new Error('Panic at the disco!')
      return {
        ...state,
        beats: state.beats.map(b =>
          b !== beat
            ? b
            : {
                ...b,
                ...hist.prevValue,
              },
        ),
        history: hist.prev,
        future: {
          next: state.future,
          type: 'paste',
          id: hist.id,
          prevValue: {
            position: beat.position,
            color: beat.color,
          },
        },
      }
    },
    redo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) throw new Error('Panic at the disco!')
      return {
        ...state,
        beats: state.beats.map(b =>
          b !== beat
            ? b
            : {
                ...b,
                ...hist.prevValue,
              },
        ),
        history: {
          type: 'paste',
          prev: state.history,
          id: hist.id,
          prevValue: {
            position: beat.position,
            color: beat.color,
          },
        },
        future: hist.next,
      }
    },
  },

  add: {
    undo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) throw new Error('Panic at the disco!')
      return {
        ...state,
        beats: state.beats.filter(b => b !== beat),
        history: hist.prev,
        future: {
          next: state.future,
          type: 'add',
          beat,
        },
      }
    },
    redo(state, hist) {
      const index = state.beats.findIndex(b => b.id > hist.beat.id)
      const history = {
        prev: state.history,
        type: 'add',
        id: hist.beat.id,
      } as const
      if (index < 0) {
        return {
          ...state,
          beats: state.beats.concat(hist.beat),
          history,
          future: hist.next,
        }
      }
      return {
        ...state,
        beats: [
          ...state.beats.slice(0, index),
          hist.beat,
          ...state.beats.slice(index),
        ],
        history,
        future: hist.next,
      }
    },
  },

  remove: {
    undo(state, hist) {
      const index = state.beats.findIndex(b => b.id > hist.beat.id)
      const future = {
        next: state.future,
        type: 'remove',
        id: hist.beat.id,
      } as const
      if (index < 0) {
        return {
          ...state,
          beats: state.beats.concat(hist.beat),
          history: hist.prev,
          future,
        }
      }
      return {
        ...state,
        beats: [
          ...state.beats.slice(0, index),
          hist.beat,
          ...state.beats.slice(index),
        ],
        history: hist.prev,
        future,
      }
    },
    redo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) throw new Error('Panic at the disco!')
      return {
        ...state,
        beats: state.beats.filter(b => b !== beat),
        history: {
          type: 'remove',
          prev: state.history,
          beat,
        },
        future: hist.next,
      }
    },
  },
  set: {
    undo(state, hist) {
      return {
        ...state,
        beats: state.beats.map(b =>
          b.id === hist.id ? { ...b, [hist.field]: hist.prevValue } : b,
        ),
        history: hist.prev,
        future: {
          next: state.future,
          type: 'set',
          id: hist.id,
          field: hist.field,
          prevValue: hist.prevValue,
        },
      }
    },

    redo(state, hist) {
      return {
        ...state,
        beats: state.beats.map(b =>
          b.id === hist.id ? { ...b, [hist.field]: hist.prevValue } : b,
        ),
        history: {
          prev: state.history,
          type: 'set',
          id: hist.id,
          field: hist.field,
          prevValue: hist.prevValue,
        },
        future: hist.next,
      }
    },
  },
  'set-color': {
    undo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) {
        return {
          ...state,
          beats: state.beats,
          history: hist.prev,
          future: null,
        }
      }
      return {
        ...state,
        beats: state.beats.map(b =>
          b === beat ? { ...b, color: hist.prevValue } : b,
        ),
        history: hist.prev,
        future: {
          next: state.future,
          type: 'set-color',
          id: hist.id,
          prevValue: beat.color,
        },
      }
    },

    redo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) {
        return {
          ...state,
          beats: state.beats,
          history: state.history,
          future: null,
        }
      }
      return {
        ...state,
        beats: state.beats.map(b =>
          b === beat ? { ...b, color: hist.prevValue } : b,
        ),
        history: {
          prev: state.history,
          type: 'set-color',
          id: hist.id,
          prevValue: beat.color,
        },
        future: hist.next,
      }
    },
  },
  'set-position': {
    undo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) {
        return {
          ...state,
          beats: state.beats,
          history: hist.prev,
          future: null,
        }
      }
      return {
        ...state,
        beats: state.beats.map(b =>
          b === beat ? { ...b, position: hist.prevValue } : b,
        ),
        history: hist.prev,
        future: {
          next: state.future,
          type: 'set-color',
          id: hist.id,
          prevValue: beat?.color,
        },
      }
    },
    redo(state, hist) {
      const beat = state.beats.find(b => b.id === hist.id)
      if (!beat) {
        return {
          ...state,
          beats: state.beats,
          history: state.history,
          future: null,
        }
      }
      return {
        ...state,
        beats: state.beats.map(b =>
          b === beat ? { ...b, position: hist.prevValue } : b,
        ),
        history: {
          prev: state.history,
          type: 'set-position',
          id: hist.id,
          prevValue: beat.position,
        },
        future: hist.next,
      }
    },
  },
}

function beatsReducer(
  state: BeatsState,
  action: BeatAction | { type: 'undo' } | { type: 'redo' },
): BeatsState {
  if (action.type === 'add') {
    const id =
      state.beats.map(v => v.id).reduce((a, b) => Math.max(a, b), 0) + 1
    return {
      ...state,
      beats: [
        ...state.beats,
        {
          position: { x: 0, y: 1 },
          id,
          color: 'gray',
          time: action.time,
          type: 'oneshot',
        },
      ],
      history: { prev: state.history, type: 'add', id },
      future: null,
    }
  }
  if (action.type === 'remove') {
    const removed = state.beats.find(v => v.id === action.id)
    if (!removed) return state
    return {
      ...state,
      beats: state.beats.filter(v => v !== removed),
      history: { prev: state.history, type: 'remove', beat: removed },
      future: null,
    }
  }
  if (action.type === 'set-color') {
    const changed = state.beats.find(v => v.id === action.id)
    if (!changed) return state
    return {
      ...state,
      beats: state.beats.map(b =>
        b === changed ? { ...b, color: action.value } : b,
      ),
      history: {
        prev: state.history,
        type: 'set-color',
        id: changed.id,
        prevValue: changed.color,
      },
      future: null,
    }
  }
  if (action.type === 'set-position') {
    const changed = state.beats.find(b => b.id === action.id)
    if (!changed) return state
    return {
      ...state,
      beats: state.beats.map(b =>
        b === changed ? { ...b, position: action.value } : b,
      ),
      history: {
        prev: state.history,
        type: 'set-position',
        id: changed.id,
        prevValue: changed.position,
      },
      future: null,
    }
  }
  if (action.type === 'redo') {
    if (!state.future) return state
    const hist = state.future

    return actions[hist.type].redo(state, hist as any)
  }
  if (action.type === 'undo') {
    if (!state.history) return state
    const hist = state.history

    return actions[hist.type].undo(state, hist as any)
  }
  if (action.type === 'copy') {
    const beat = state.beats.find(b => b.id === action.id)
    if (!beat) throw new Error('Panic at the disco!')
    return {
      ...state,
      clipboard: {
        color: beat.color,
        position: beat.position,
      },
    }
  }
  if (action.type === 'paste') {
    const beat = state.beats.find(b => b.id === action.id)
    const { clipboard } = state
    if (!clipboard) {
      return state
    }
    if (!beat) throw new Error('Panic at the disco!')
    return {
      ...state,
      beats: state.beats.map(b =>
        b !== beat
          ? b
          : {
              ...b,
              ...clipboard,
            },
      ),
      history: {
        prev: state.history,
        type: 'paste',
        id: action.id,
        prevValue: {
          position: beat.position,
          color: beat.color,
        },
      },
      future: null,
    }
  }
  if (action.type === 'set-time') {
    const changed = state.beats.find(v => v.id === action.id)
    if (!changed) return state

    return {
      ...state,
      beats: state.beats.map(b =>
        b === changed ? { ...b, time: action.value } : b,
      ),
      history: {
        prev: state.history,
        type: 'set',
        field: 'time',
        id: changed.id,
        prevValue: changed.time,
      },
      future: null,
    }
  }
  return {
    ...state,
    [action.type]: action.value,
    history: {
      prev: state.history,
      type: action.type as any,
      prevValue: state[action.type],
    },
    future: null,
  }
}

export function useLevelState(initial: any) {
  const audio = useAudio()

  const [state, dispatch] = useReducer(beatsReducer, null, () => {
    const parsed = typeof initial === 'object' && initial
    return {
      levelid: parsed.levelid,
      beats: !Array.isArray(parsed.beats)
        ? []
        : (parsed.beats as any[])
            .filter(
              (b: any) =>
                typeof b === 'object' &&
                b &&
                ['oneshot'].includes(b.type) &&
                Number.isInteger(b.id) &&
                b.id > 0,
            )
            // unique id
            .filter((b, idx, arr) => arr.findIndex(o => o.id === b.id) === idx)
            .map(
              (b: any): Beat => ({
                id: b.id,
                time:
                  (Number.isFinite(b.time) && b.time >= 0 ? b.time : 0) ||
                  (Number.isFinite(b.offset) && b.offset >= 0 ? b.offset : 0),
                position:
                  typeof b.position === 'object' &&
                  b.position &&
                  Number.isFinite(b.position.x) &&
                  Number.isFinite(b.position.y)
                    ? b.position
                    : { x: 0, y: 0 },
                color: isBeatColor(b.color) ? b.color : 'gray',
                type: 'oneshot',
              }),
            ),
      songName: (typeof parsed.songName === 'string' && parsed.songName) || '',
      songAuthor:
        (typeof parsed.songAuthor === 'string' && parsed.songAuthor) || '',
      levelAuthor:
        (typeof parsed.levelAuthor === 'string' && parsed.levelAuthor) || '',
      levelName:
        (typeof parsed.levelName === 'string' && parsed.levelName) || '',
      history: null,
      future: null,
      clipboard: null,
    }
  })
  useEffect(() => {
    audio.setBeats(state.beats)
  }, [audio, state, state.beats])
  return [
    useMemo(
      () => ({
        beats: state.beats,
        hasUndo: !!state.history,
        hasRedo: !!state.future,
        canPaste: !!state.clipboard,
        songName: state.songName,
        songAuthor: state.songAuthor,
        levelAuthor: state.levelAuthor,
        levelName: state.levelName,
        levelid: state.levelid,
        example: state.levelid === 'example',
      }),
      [state],
    ),
    dispatch,
  ] as const
}

export function saveLevel(
  levelState: Pick<
    ReturnType<typeof useLevelState>[0],
    | 'levelName'
    | 'songName'
    | 'songAuthor'
    | 'levelAuthor'
    | 'beats'
    | 'levelid'
    | 'example'
  >,
  buffer: ArrayBuffer | Blob,
) {
  if (levelState.example) return Promise.resolve()
  return fetch(gameServer + '/level/' + levelState.levelid, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      songName: levelState.songName,
      songAuthor: levelState.songAuthor,
      levelAuthor: levelState.levelAuthor,
      levelName: levelState.levelName,
      beats: levelState.beats,
    }),
  })
    .then(r => r.json())
    .then(v => {
      if (v.success) return
      else if (v.ogg === 'missing') {
        return new Response(
          'byteLength' in buffer ? copyBuffer(buffer) : buffer,
        )
          .arrayBuffer()
          .then(copy =>
            fetch(gameServer + '/audio/' + levelState.levelid + '.ogg', {
              method: 'POST',
              body: copy,
            }),
          )
          .then(v => v.json())
          .then(v => {
            if (!v.success) throw new Error('Ogg save failed')
          })
      } else {
        throw new Error('JSON save failed')
      }
    })
    .then(() => {})
}

export function useLevelStateAutosave(
  levelState: ReturnType<typeof useLevelState>[0],
  buffer: ArrayBuffer,
) {
  const [lastSaved, setLastSaved] = useState<
    ReturnType<typeof useLevelState>[0] | null
  >(null)
  const pending = useRef({ promise: Promise.resolve(), isPending: false })

  useEffect(() => {
    function handle() {
      function run(delay: number) {
        const p = {
          promise: pending.current.promise
            .then(() =>
              delay > 0
                ? new Promise(res => setTimeout(res, delay))
                : Promise.resolve(),
            )
            .then(() =>
              saveLevel(levelState, buffer)
                .then(() => {
                  setLastSaved(levelState)
                })
                .catch(e => {
                  if (pending.current === p) {
                    run(1000)
                  }
                  console.error(e)
                })
                .then(() => {
                  p.isPending = false
                }),
            ),
          isPending: true,
        }
        pending.current = p
      }
      run(0)
    }
    function timeoutCb() {
      if (pending.current.isPending) timeout = setTimeout(timeoutCb, 1000)
      handle()
    }
    let timeout = setTimeout(timeoutCb, 1000)
    return () => clearTimeout(timeout)
  }, [buffer, levelState])
  return lastSaved === levelState
}
