import { Canvas, useFrame, useThree, CanvasContext } from 'react-three-fiber'
import { useRef, useEffect, memo, useReducer, Dispatch } from 'react'
import {
  PerspectiveCamera,
  Euler,
  Vector3,
  Mesh,
  Raycaster,
  Color,
} from 'three'
import { useAudio } from './use-audio'
import { colors, Beat } from './constants'
import { useLevelState } from './level-state'
import { useClickHandler, useHoverTime } from './tools'

const BeatComp = memo(function BeatComp({
  beat,
  audio,
  lockDispatch,
}: {
  beat: Beat
  audio: ReturnType<typeof useAudio>
  lockDispatch: Dispatch<LockAction>
}) {
  const { color } = beat
  const ref = useRef<Mesh>()
  useFrame(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ref.current!.visible = audio.time < beat.time
  })
  return (
    <mesh
      ref={ref}
      onPointerDown={e => {
        lockDispatch({ type: 'lock', id: beat.id })
      }}
      position={[beat.position.x, beat.position.y, -beat.time]}
      scale={new Vector3(0.1, 0.1, 0.1)}
      userData={{ beatId: beat.id }}
    >
      <sphereBufferGeometry attach="geometry" args={[1, 10, 12]} />
      <meshPhongMaterial attach="material" color={colors[color]} />
    </mesh>
  )
})

/**
 * Code copied from https://stackoverflow.com/a/9493060
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Color}           The RGB representation
 */
function hslToRgb(h: number, s: number, l: number) {
  var r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return new Color(r, g, b)
}

function FloorPart({
  idx,
  timeToHueCoef,
}: {
  idx: number
  timeToHueCoef: number
}) {
  const ref = useRef<Mesh>()
  return (
    <mesh ref={ref} position={[0, 0, -idx]} rotation={[-Math.PI / 2, 0, 0]}>
      <planeBufferGeometry attach="geometry" args={[2, 0.98]} />
      <meshPhongMaterial
        attach="material"
        color={hslToRgb(((timeToHueCoef * idx) % 360) / 360, 0.6, 0.6)}
      />
    </mesh>
  )
}
const Floor = memo(function Floor({
  duration,
  timeToHueCoef,
}: {
  duration: number
  timeToHueCoef: number
}) {
  return (
    <>
      {Array(Math.ceil(duration))
        .fill(0)
        .map((_, i) => (
          <FloorPart idx={i} key={i} timeToHueCoef={timeToHueCoef} />
        ))}
    </>
  )
})

function Camera({ audio }: { audio: ReturnType<typeof useAudio> }) {
  const ref = useRef<PerspectiveCamera>()
  const { setDefaultCamera } = useThree()
  // Make the camera known to the system
  useEffect(() => {
    const cam = ref.current
    if (!cam) return

    setDefaultCamera(cam)
  }, [setDefaultCamera])

  // Update it every frame based on time
  useFrame(() => {
    const cam = ref.current
    if (!cam) return
    cam.position.z = 1 - audio.time
    cam.updateMatrixWorld()
  })
  const position = [1, 1, 1]
  const rotation = new Euler((0 / 180) * Math.PI, (30 / 180) * Math.PI)

  return (
    <>
      <perspectiveCamera ref={ref} position={position} rotation={rotation} />
      <directionalLight position={position} rotation={rotation} />
    </>
  )
}

function clamp(min: number, max: number) {
  return (v: number) => {
    if (v < min) return min
    if (v > max) return max
    return v
  }
}

const clampPositionX = clamp(-1, 1)
const clampPositionY = clamp(0.2, 1.8)

function BeatMover({
  beat,
  dispatch,
}: {
  beat?: Beat
  dispatch: ReturnType<typeof useLevelState>[1]
}) {
  if (!beat) return null
  return (
    <mesh
      position={[beat.position.x, beat.position.y + 1, -beat.time]}
      onPointerMove={evt => {
        dispatch({
          type: 'set-position',
          value: {
            x: clampPositionX(evt.point.x),
            y: clampPositionY(evt.point.y),
          },
          id: beat.id,
        })
      }}
    >
      <planeBufferGeometry attach="geometry" args={[10, 10]} />
      <meshBasicMaterial attach="material" transparent={true} opacity={0} />
    </mesh>
  )
}

type LockAction = { type: 'lock'; id: number } | { type: 'unlock' }
function lockReducer(state: number, action: LockAction) {
  if (action.type === 'unlock') return -1
  if (action.type === 'lock') return state < 0 ? action.id : state
  throw new Error('Invalid action.type')
}

export function Level3D({
  beats,
  dispatch,
  mode,
  timeToHueCoef,
}: {
  beats: readonly Beat[]
  dispatch: ReturnType<typeof useLevelState>[1]
  mode: string
  timeToHueCoef: number
}) {
  const hoverTime = useHoverTime()
  const { triggerClickHandler } = useClickHandler()
  const [lockedBeat, lockDispatch] = useReducer(lockReducer, -1)
  const audio = useAudio()

  useEffect(() => {
    function listener() {
      lockDispatch({ type: 'unlock' })
      const beat = beats.find(b => b.id === lockedBeat)
      if (!beat) return
      triggerClickHandler(beat.time, beat, () => {})
    }
    window.addEventListener('pointerup', listener)
    return () => window.removeEventListener('pointerup', listener)
  }, [beats, lockedBeat, triggerClickHandler])

  const canvas = useRef<CanvasContext | null>(null)
  const C = Canvas as any

  return (
    <div
      css={{
        flexGrow: 0,
        flexShrink: 0,
        height:
          mode === 'split'
            ? 'calc((100vh - 45px) / 2)'
            : mode === 'preview'
            ? 'calc((100vh - 45px) * 0.8)'
            : 0,
      }}
    >
      <C
        onCreated={(c: CanvasContext) => {
          canvas.current = c
        }}
        onMouseOver={function over(evt: MouseEvent) {
          if (!canvas.current) return
          hoverTime.current = () => {
            const c = canvas.current
            if (!c) return { time: -1, closestBeat: null }
            const raycaster = new Raycaster()
            raycaster.setFromCamera(c.mouse, c.camera)
            const intersects = raycaster.intersectObjects(c.scene.children)
            for (const i of intersects) {
              const { beatId } = i.object.userData
              if (typeof beatId !== 'number') continue
              const beat = beats.find(b => b.id === beatId)
              if (beat) return { time: beat.time, closestBeat: beat }
            }
            return { time: -1, closestBeat: null }
          }
        }}
        onMouseOut={() => {
          if (!canvas.current) return
          hoverTime.current = { time: -1, closestBeat: null }
        }}
      >
        <ambientLight intensity={0.2} />
        <Camera audio={audio} />
        {beats.map(b => (
          <BeatComp
            beat={b}
            key={b.id}
            audio={audio}
            lockDispatch={lockDispatch}
          />
        ))}
        <BeatMover
          beat={beats.find(b => b.id === lockedBeat)}
          dispatch={dispatch}
        />
        <Floor duration={audio.buffer.duration} timeToHueCoef={timeToHueCoef} />
      </C>
    </div>
  )
}
